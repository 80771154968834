var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("staff.title_work_effort"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  !_vm.readOnly
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.preOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "d-none d-sm-flex mb-sm-2" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.$t("staff.field.staff"))),
              ]),
              _c("b-col", { attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.$t("staff.field.effortToDate"))),
              ]),
              _c("b-col", { attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.$t("staff.field.newEffort"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "work-effort" },
            _vm._l(_vm.workEffortList, function (item, index) {
              return _c(
                "b-row",
                { key: index, staticClass: "effort-item" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-sm-0 mb-1",
                      attrs: { cols: "12", sm: "4" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { size: "sm", readonly: "" },
                        model: {
                          value: item.name,
                          callback: function ($$v) {
                            _vm.$set(item, "name", $$v)
                          },
                          expression: "item.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-sm-0 mb-1",
                      attrs: { cols: "12", sm: "4" },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            staticClass: "effort-to-date-input",
                            attrs: { size: "sm", readonly: "" },
                            model: {
                              value: item.durationDisplay,
                              callback: function ($$v) {
                                _vm.$set(item, "durationDisplay", $$v)
                              },
                              expression: "item.durationDisplay",
                            },
                          }),
                          !_vm.readOnly
                            ? _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "effort-remove-icon-container",
                                      attrs: {
                                        id: `BTN_CLEAR_EFFORT_${_vm.id}_${index}`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.preEffortToDateClear(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "effort-remove-icon",
                                        attrs: { icon: ["far", "xmark"] },
                                      }),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target: `BTN_CLEAR_EFFORT_${_vm.id}_${index}`,
                                            triggers: "hover",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "task.button.clear_effort"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-2", attrs: { cols: "10", sm: "3" } },
                    [
                      _c("b-form-input", {
                        ref: "newEffort",
                        refInFor: true,
                        attrs: { disabled: _vm.readOnly, size: "sm" },
                        model: {
                          value: item.newEffort,
                          callback: function ($$v) {
                            _vm.$set(item, "newEffort", $$v)
                          },
                          expression: "item.newEffort",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.readOnly
                    ? _c(
                        "b-col",
                        { staticClass: "pl-0", attrs: { cols: "2", sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "working-action",
                              attrs: {
                                id: `BTN_COMMIT_${_vm.id}_${index}`,
                                size: "sm",
                                variant: "secondary-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.commitNewEffort(index)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: `BTN_COMMIT_${_vm.id}_${index}`,
                                triggers: "hover",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("button.commit")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c(
                "b-col",
                { staticClass: "pr-sm-0", attrs: { cols: "12", sm: "4" } },
                [
                  _c(
                    "div",
                    { staticClass: "float-sm-right mb-1 mb-sm-0 pt-sm-1" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.childTaskEfforts > 0
                              ? _vm.$t("staff.task_total")
                              : _vm.$t("staff.total_work_effort")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "pr-sm-0", attrs: { cols: "12", sm: "4" } },
                [
                  _c("b-form-input", {
                    attrs: { size: "sm", value: _vm.taskEffort, disabled: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.childTaskEfforts > 0
            ? [
                _c(
                  "b-row",
                  { staticClass: "mb-1" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "pr-sm-0",
                        attrs: { cols: "12", sm: "4" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "float-sm-right mb-1 mb-sm-0 pt-sm-1",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("staff.child_task_efforts")) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "pr-sm-0",
                        attrs: { cols: "12", sm: "4" },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            size: "sm",
                            value: _vm.childTaskEffortDisplay,
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "pr-sm-0",
                        attrs: { cols: "12", sm: "4" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "float-sm-right mb-1 mb-sm-0 pt-sm-1",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("staff.total_work_effort")) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "pr-sm-0",
                        attrs: { cols: "12", sm: "4" },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            size: "sm",
                            value: _vm.totalWorkEffort,
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.confirmation.title_clear_effort"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.confirmClearEffortShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.effortToDateClear },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmClearEffortShow,
            callback: function ($$v) {
              _vm.confirmClearEffortShow = $$v
            },
            expression: "confirmClearEffortShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("staff.confirmation.clear_effort")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.confirmation.title_uncommitted_effort"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            cancel: _vm.ok,
            hidden: function ($event) {
              _vm.confirmCommitNewEffortShow = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmCommitNewEffortOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmCommitNewEffortShow,
            callback: function ($$v) {
              _vm.confirmCommitNewEffortShow = $$v
            },
            expression: "confirmCommitNewEffortShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("staff.confirmation.uncommitted_effort")) +
                " "
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }