<template>
  <div>
    <b-modal  v-model="modalShow" title="Download" @close="downloadCancel"
      centered hide-footer content-class="shadow" no-close-on-backdrop>
      <b-progress :max="100" height="2rem">
        <b-progress-bar :value="downloadPercentage" :label="`${downloadPercentage.toFixed(0)}%`"/>
      </b-progress>
      <div class="d-flex justify-content-center">
        <b-button class="visible mt-2" variant="secondary"  @click="downloadCancel">
          {{ $i18n.t('button.cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'DownloadProgressModal',
  props: {
    show:               { type: Boolean, required: true },
    downloadPercentage: { type: Number, default: 0 } 
  },
  data() {
    return {
      modalShow: false
    }
  },
  mounted() {
    this.modalShow = this.show;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
      }
    }
  },
  methods: {
    downloadCancel() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    }
  }
}
</script>
